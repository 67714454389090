.contact-us-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 2rem;
    background-color: #f9f9f9;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  /* h1 {
    color: #333;
    text-align: center;
    margin-bottom: 1rem;
  }
  
  p {
    color: #666;
    text-align: center;
    margin-bottom: 2rem;
  } */
  
  .contact-info {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    margin-bottom: 2rem;
  }
  
  .info-item {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
  }
  
  .info-item svg {
    color: #4a4a4a;
    margin-right: 0.5rem;
    font-size: 1.2rem;
  }
  
  form {
    display: grid;
    grid-gap: 1rem;
  }
  
  .form-group input,
  .form-group textarea {
    width: 100%;
    padding: 0.8rem;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 1rem;
  }
  
  .form-group textarea {
    height: 150px;
    resize: vertical;
  }
  
  button {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 1rem;
    border-radius: 5px;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.3s;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  button:hover {
    background-color: #0056b3;
  }
  
  button svg {
    margin-right: 0.5rem;
  }